/* .App {
  text-align: center;
}

body {
  background-color: #4b5562;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.bg-cover {
  background-image: url('/public/images/digital-banner.webp') ;
}
 */



/* Base App Styles */
.App {
  text-align: center;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Light Mode Variables */
body.light {
  --background-color: #f5f5f5;
  --text-color: #333;
}

/* Dark Mode Variables */
body.dark {
  --background-color: #1f2937;
  --text-color: #e5e7eb;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: var(--header-bg-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--text-color);
}

body.light .App-header {
  --header-bg-color: #ffffff;
}

body.dark .App-header {
  --header-bg-color: #282c34;
}

.App-link {
  color: var(--link-color);
}

body.light .App-link {
  --link-color: #1a73e8;
}

body.dark .App-link {
  --link-color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Background Cover Image */
.bg-cover {
  background-image: url('/public/images/digital-banner.webp');
  background-size: cover;
  background-position: center;
}

/* Theme Toggle Button Styles */
.theme-toggle-btn {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 10px 15px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: var(--button-bg-color);
  color: var(--button-text-color);
  transition: background-color 0.3s ease, color 0.3s ease;
}

body.light .theme-toggle-btn {
  --button-bg-color: #007bff;
  --button-text-color: #ffffff;
}

body.dark .theme-toggle-btn {
  --button-bg-color: #333;
  --button-text-color: #ffffff;
}

.theme-toggle-btn:hover {
  opacity: 0.9;
}